export const pageNameByDefworkflow = (defWorkflow, fromPage) => {
  switch (defWorkflow) {
    case 1: // REGISTRATION SHIPPER
    case 2: // REGISTRATION TRANSPORTER
    case 15: // DOCUMENT SUPPORT
      return `${fromPage}-company-registration`;
    case 5: // MOU BY SPV MARKETING
    case 6: // MOU BY MARKETING MANAGER
    case 8: // ADDENDUM MOU BY SPV MARKETING
    case 9: // ADDENDUM MOU BY MARKETING MANAGER
      return `${fromPage}-mou`;
    case 16:
    case 7: // CONTRACT
      return `${fromPage}-contract`;
    case 11: // ADDENDUM CONTRACT
      return `${fromPage}-addendum-contract`;
    case 14: // TERMINATION CONTRACT
      return `${fromPage}-contract-termination`;
    default:
      break;
  }
};

export const servicesByDefworkflow = (defWorkflow) => {
  switch (defWorkflow) {
    case 1:
      return {
        service: 'companyRegistration',
        function: 'getShipperRegistrationByWorkflowId',
      };
    case 2:
      return {
        service: 'companyRegistration',
        function: 'getTransporterRegistrationByWorkflowId',
      };
    case 15:
      return {
        service: 'approval',
        function: 'getWorkflow',
      };
    case 5:
    case 6:
    case 8:
    case 9:
      return {
        service: 'mou',
        function: 'getDetailWorkflowMou',
      };
    case 16:
    case 7:
    case 11:
    case 14:
      return {
        service: 'contract',
        function: 'getDetailWorkflowContract',
      };
    default:
      break;
  }
};
